<script setup>
import LogoEstacion from "@/components/LogoEstacion.vue";
import { ref } from "vue";

const estaciones = ref([
  {
    title: "La Patrona Puerto Vallarta/Bahía de Banderas",
    freq: "93.5 FM",
    logo: "logo-la-patrona-vallarta.png",
    url: "https://vallarta.lapatrona.fm/",
  },
  {
    title: "La Patrona Acaponeta",
    freq: "98.1 FM",
    logo: "logo-la-patrona-acaponeta.png",
    url: "https://acaponeta.lapatrona.fm/",
  },
  {
    title: "La Patrona Escuinapa/Tecuala",
    freq: "92.5 FM y 570 AM",
    logo: "logo-la-patrona-escuinapa.png",
    url: "https://lapatrona.fm/escuinapa/",
  },
  {
    title: "La Patrona Compostela",
    freq: "91.3 FM",
    logo: "logo-la-patrona-compostela.png",
    url: "https://lapatrona.fm/compostela/",
  },
  {
    title: "La Patrona Santiago",
    freq: "94.5 FM",
    logo: "logo-la-patrona-santiago.png",
    url: "https://lapatrona.fm/santiago/",
  },
]);

const estacionesExternas = ref([
  {
    title: "La mejor de Tepic",
    freq: "99.3 FM",
    logo: "logo-la-mejor-tepic.png",
    url: "https://lamejor.com.mx/plaza/tepic/",
  },
  {
    title: "La mejor de Ixtlán",
    freq: "96.5 FM",
    logo: "logo-la-mejor-ixtlan.png",
    url: "https://lamejor.com.mx/plaza/ixtlan/",
  },
  {
    title: "EXA Puerto Vallarta/Bahía de Banderas",
    freq: "89.9 FM",
    logo: "logo-exa-puerto-vallarta.png",
    url: "https://exafm.com/plaza/puerto-vallarta/",
  },
]);
</script>

<template>
  <div id="estaciones" class="observable min-h-[80vh] pt-[6vh] md:pt-[7vh] lg:pt-[11vh]">
    <div class="min-h-[60vh] container px-6 mx-auto flex flex-col pt-[6vh] md:pt-[10vh]">
      <div
        class="text-[2.5rem] md:text-[3.5rem] lg:text-[5rem] w-full text-center uppercase mt-6 md:mt-0"
      >
        NUESTRAS ESTACIONES
      </div>
      <div class="text-center text-lg my-5 md:my-0 md:text-xl">
        En convenio con Grupo BD Cast, RTDN y MVS Radio
      </div>
      <div class="md:h-[8vh]"></div>
      <div class="grid gap-5 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
        <LogoEstacion
          v-for="(estacion, key) in estaciones"
          :key="key"
          :estacion="estacion"
        />
      </div>
      <div class="grid gap-5 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
        <LogoEstacion
          :class="{ 'md:col-start-2': key === 0 }"
          v-for="(estacionExterna, key) in estacionesExternas"
          :key="key"
          :estacion="estacionExterna"
        />
      </div>
    </div>
    <div class="h-[10vh]"></div>
  </div>
</template>

<style scoped>
#estaciones {
  background-image: url("@/assets/fonto-estaciones.svg");
  background-attachment: fixed;
}
</style>
